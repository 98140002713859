<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              :ripple="false"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ form.id ? '卓フィルタ編集' : '新規卓フィルタ' }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="form.name"
                label="名前"
                suffix="の卓"
                :error="!form.name"
              />

              <v-card-subtitle
                class="text-sm pa-0 d-flex justify-space-between align-center pb-2 mb-4"
              >
                商品

                <v-btn
                  icon
                  color="primary"
                  :ripple="false"
                  @click="form.tableFilterVendiblesAttributes.push({ id: null, has: true, vendibleId: null, vendibleType: null })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card-subtitle>

              <v-card
                v-for="(tableFilterVendible, tableFilterVendiblesAttributeIdx) in form.tableFilterVendiblesAttributes"
                :key="`tableFilterVendiblesAttribute[${tableFilterVendiblesAttributeIdx}]`"
                class="mb-6 position-relative"
                :class="tableFilterVendible._destroy ? '_destroy-color' : ''"
              >
                <v-icon
                  class="remove-btn"
                  @click="
                    tableFilterVendible.id
                      ? tableFilterVendible._destroy = !tableFilterVendible._destroy
                      : form.tableFilterVendiblesAttributes.splice(tableFilterVendiblesAttributeIdx, 1)"
                >
                  {{ icons.mdiCloseBox }}
                </v-icon>

                <v-card-text>
                  <v-row>
                    <v-col
                      md="6"
                      sm="12"
                    >
                      <v-select
                        :value="tableFilterVendible"
                        :items="vendibleItems"
                        :item-value="item => {
                          if (item.attributes) return { vendibleId: item.id, vendibleType: item.attributes.vendibleType }
                        }"
                        :value-comparator="(a, b) => {
                          if (a && b) return +a.vendibleId === +b.vendibleId && a.vendibleType === b.vendibleType
                        }"
                        label="ターゲット"
                        no-data-text="選択可能なデータがありません"
                        :error="!(tableFilterVendible.vendibleId && tableFilterVendible.vendibleType)"
                        hide-details
                        :menu-props="{ auto: true, offsetY: true }"
                        @change="
                          tableFilterVendible.vendibleId = $event.vendibleId;
                          tableFilterVendible.vendibleType = $event.vendibleType"
                      >
                        <template #selection="{ item }">
                          {{ item.attributes.name }}
                        </template>

                        <template #item="{ item }">
                          <template v-if="!item.attributes">
                            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                            <v-list-item-content v-text="item.header" />
                          </template>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.attributes.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col
                      md="6"
                      sm="12"
                    >
                      <v-select
                        v-model="tableFilterVendible.has"
                        :menu-props="{ auto: true, offsetY: true }"
                        :items="[
                          { value: true, text: 'を受領している' },
                          { value: false, text: 'を受領していない' },
                        ]"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card-subtitle
                class="text-sm pa-0 d-flex justify-space-between align-center pb-2 mb-4"
              >
                紹介

                <v-btn
                  icon
                  color="primary"
                  :ripple="false"
                  @click="form.tableFilterReferralsAttributes.push({ id: null, has: true, referralId: null })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card-subtitle>

              <v-card
                v-for="(tableFilterReferral, tableFilterReferralsAttributeIdx) in form.tableFilterReferralsAttributes"
                :key="`tableFilterReferralsAttribute[${tableFilterReferralsAttributeIdx}]`"
                class="mb-6 position-relative"
                :class="tableFilterReferral._destroy ? '_destroy-color' : ''"
              >
                <v-icon
                  class="remove-btn"
                  @click="
                    tableFilterReferral.id
                      ? tableFilterReferral._destroy = !tableFilterReferral._destroy
                      : form.tableFilterReferralsAttributes.splice(tableFilterReferralsAttributeIdx, 1)"
                >
                  {{ icons.mdiCloseBox }}
                </v-icon>

                <v-card-text>
                  <v-row>
                    <v-col
                      md="6"
                      sm="12"
                    >
                      <v-select
                        :value="tableFilterReferral"
                        :items="referrals"
                        :item-value="item => {
                          if (item.attributes) return { referralId: item.id }
                        }"
                        :value-comparator="(a, b) => {
                          if (a && b) return +a.referralId === +b.referralId
                        }"
                        label="ターゲット"
                        item-text="attributes.name"
                        no-data-text="選択可能なデータがありません"
                        :error="!(tableFilterReferral.referralId)"
                        hide-details
                        :menu-props="{ auto: true, offsetY: true }"
                        @change="tableFilterReferral.referralId = $event.referralId"
                      />
                    </v-col>

                    <v-col
                      md="6"
                      sm="12"
                    >
                      <v-select
                        v-model="tableFilterReferral.has"
                        :items="[
                          { value: true, text: 'で紹介している' },
                          { value: false, text: 'で紹介していない' },
                        ]"
                        :menu-props="{ auto: true, offsetY: true }"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card-subtitle
                class="text-sm pa-0 d-flex justify-space-between align-center pb-2 mb-4"
              >
                顧客タグ

                <v-btn
                  icon
                  color="primary"
                  :ripple="false"
                  @click="form.tableFilterCustomerTagsAttributes.push({ id: null, has: true, customerTagId: null })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card-subtitle>

              <v-card
                v-for="(tableFilterCustomerTag, tableFilterCustomerTagsAttributeIdx) in form.tableFilterCustomerTagsAttributes"
                :key="`tableFilterCustomerTagsAttribute[${tableFilterCustomerTagsAttributeIdx}]`"
                class="mb-6 position-relative"
                :class="tableFilterCustomerTag._destroy ? '_destroy-color' : ''"
              >
                <v-icon
                  class="remove-btn"
                  @click="
                    tableFilterCustomerTag.id
                      ? tableFilterCustomerTag._destroy = !tableFilterCustomerTag._destroy
                      : form.tableFilterCustomerTagsAttributes.splice(tableFilterCustomerTagsAttributeIdx, 1)"
                >
                  {{ icons.mdiCloseBox }}
                </v-icon>

                <v-card-text>
                  <v-row>
                    <v-col
                      md="6"
                      sm="12"
                    >
                      <v-select
                        :value="tableFilterCustomerTag"
                        :items="customerTags"
                        :item-value="item => {
                          if (item.attributes) return { customerTagId: item.id }
                        }"
                        :value-comparator="(a, b) => {
                          if (a && b) return +a.customerTagId === +b.customerTagId
                        }"
                        label="ターゲット"
                        item-text="attributes.name"
                        no-data-text="選択可能なデータがありません"
                        :error="!(tableFilterCustomerTag.customerTagId)"
                        hide-details
                        :menu-props="{ auto: true, offsetY: true }"
                        @change="tableFilterCustomerTag.customerTagId = $event.customerTagId"
                      />
                    </v-col>

                    <v-col
                      md="6"
                      sm="12"
                    >
                      <v-select
                        v-model="tableFilterCustomerTag.has"
                        :items="[
                          { value: true, text: 'がついている' },
                          { value: false, text: 'がついていない' },
                        ]"
                        :menu-props="{ auto: true, offsetY: true }"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                :disabled="!isValid"
                :loading="isSubmitting"
                @click="submit"
              >
                {{ form.id ? '更新' : '作成' }}
              </v-btn>

              <v-btn
                :ripple="false"
                color="blue darken-1"
                text
                @click="cancelForm"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :headers="headers"
        :items="tableFilters"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
      >
        <template #[`item.actions`]="{item}">
          <span class="d-flex align-center justify-end">
            <v-btn
              icon
              :ripple="false"
              @click="edit(item)"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <yona-edit-dialog
              title="削除の確認"
              btn-color="error"
              save-text="同意の上で削除"
              @save="destroy(item.id)"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <span>
                  削除を実行してもよろしいですか
                </span>
              </template>
            </yona-edit-dialog>
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiClose,
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiCloseBox,
} from '@mdi/js'
import {
  findIndex,
  map,
  groupBy,
  each,
  every,
} from 'lodash'
import TableFilterApi from '@/api/admin/TableFilter'
import VendibleApi from '@/api/waiter/Vendible'
import ReferralApi from '@/api/waiter/Referral'
import CustomerTagApi from '@/api/waiter/CustomerTag'
import useCompInit from '@/views/composable/useCompInit'
import useCurrentData from '@/views/composable/useCurrentData'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const { currentClub } = useCurrentData()
    const isFormOpen = ref(false)
    const isSubmitting = ref(false)
    const form = reactive({
      id: null,
      name: '',
      tableFilterVendiblesAttributes: [],
      tableFilterReferralsAttributes: [],
      tableFilterCustomerTagsAttributes: [],
    })
    const tableFilters = ref([])
    const vendibles = ref([])
    const referrals = ref([])
    const customerTags = ref([])

    const isValid = computed(() => {
      return form.name
        && every(form.tableFilterVendiblesAttributes, o => o.vendibleId && o.vendibleType)
        && every(form.tableFilterReferralsAttributes, 'referralId')
        && every(form.tableFilterCustomerTagsAttributes, 'customerTagId')
    })

    const vendibleItems = computed(() => {
      const lists = []
      const typeOrder = ['Nomination', 'Item']
      const vendiblesGrouped = groupBy(vendibles.value, 'attributes.vendibleType')

      each(typeOrder, type => {
        if (!vendiblesGrouped[type]) return

        lists.push({
          header: {
            Nomination: currentClub.value.nominationAlias,
            Item: 'アイテム',
          }[type],
        })
        lists.push(...vendiblesGrouped[type])
      })

      return lists
    })

    const cancelForm = () => {
      isFormOpen.value = false

      form.id = null
      form.name = ''
      form.tableFilterVendiblesAttributes = []
      form.tableFilterReferralsAttributes = []
      form.tableFilterCustomerTagsAttributes = []
    }

    const edit = item => {
      isFormOpen.value = true

      form.id = item.id
      form.name = item.attributes.name

      const tableFilterVendiblesAttributes = map(item.attributes.tableFilterVendibles.data, o => {
        const { vendibleId, vendibleType, has } = o.attributes

        return {
          id: o.id,
          vendibleId,
          vendibleType,
          has,
          _destroy: false,
        }
      })
      form.tableFilterVendiblesAttributes = tableFilterVendiblesAttributes

      const tableFilterReferralsAttributes = map(item.attributes.tableFilterReferrals.data, o => {
        const { referralId, has } = o.attributes

        return {
          id: o.id,
          referralId,
          has,
          _destroy: false,
        }
      })
      form.tableFilterReferralsAttributes = tableFilterReferralsAttributes

      const tableFilterCustomerTagsAttributes = map(item.attributes.tableFilterCustomerTags.data, o => {
        const { customerTagId, has } = o.attributes

        return {
          id: o.id,
          customerTagId,
          has,
          _destroy: false,
        }
      })
      form.tableFilterCustomerTagsAttributes = tableFilterCustomerTagsAttributes
    }

    const submit = async () => {
      isSubmitting.value = true
      const isUpdate = !!form.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        name,
        tableFilterVendiblesAttributes,
        tableFilterReferralsAttributes,
        tableFilterCustomerTagsAttributes,
      } = form

      const res = await TableFilterApi[`${fnVerb}TableFilter`]({
        id,
        name,
        tableFilterVendiblesAttributes,
        tableFilterReferralsAttributes,
        tableFilterCustomerTagsAttributes,
      })

      if (res?.data) {
        if (isUpdate) {
          const tableFilterIdx = findIndex(tableFilters.value, o => +o.id === +res.data.tableFilter.data.id)

          tableFilters.value.splice(tableFilterIdx, 1, res.data.tableFilter.data)
        } else {
          tableFilters.value.unshift(res.data.tableFilter.data)
        }

        vm.$toast.success(isUpdate ? '卓フィルタを更新しました' : '卓フィルタを追加しました')
      }

      isSubmitting.value = false
      cancelForm()
    }

    const destroy = async id => {
      const res = await TableFilterApi.deleteTableFilter(id)

      if (res) {
        const tableFilterIdx = findIndex(tableFilters.value, o => +o.id === +id)
        tableFilters.value.splice(tableFilterIdx, 1)
        vm.$toast.success('卓フィルタを削除しました')
      }
    }

    const getTableFilters = async () => {
      const res = await TableFilterApi.getTableFilters()

      if (res?.status === 200) {
        tableFilters.value = [...res.data.tableFilters.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.status === 200) {
        vendibles.value = [...res.data.vendibles.data]
      }
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.status === 200) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    const getCustomerTags = async () => {
      const res = await CustomerTagApi.getCustomerTags()

      if (res?.status === 200) {
        customerTags.value = [...res.data.customerTags.data]
      }
    }

    initWith([
      getTableFilters(),
      getVendibles(),
      getReferrals(),
      getCustomerTags(),
    ])

    return {
      // data
      isLoading,
      isFormOpen,
      isSubmitting,
      form,
      tableFilters,
      vendibles,
      referrals,
      customerTags,
      headers: [
        {
          text: '名前',
          value: 'attributes.name',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],

      // computed
      isValid,
      vendibleItems,

      // methods
      cancelForm,
      edit,
      submit,
      destroy,

      icons: {
        mdiPlus,
        mdiClose,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiCloseBox,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.remove-btn {
  position: absolute;
  top: -8px;
  right: -8px;
}
._destroy-color {
  opacity: 0.5;
  filter: hue-rotate(30deg);
}
</style>
